<!--
 * @Descripttion: 编辑页面
 * @version: 1.1.2px
 * @Author: ivan
 * @Date: 2019-11-21 15:37:11
 * @LastEditors: cyy
 * @LastEditTime: 2022-10-08 18:05:33
 -->
<template>
  <div class="SeriesCourse_edit">
    <div class="edit_details">
      <div class="tu">
        <img width="200" height="113" :src="datalist.photo" alt />
        <div class="tu_font">
          <span v-if="datalist.status == 1">已上架</span>
          <span v-if="datalist.status == 2">已下架</span>
        </div>
      </div>
      <div class="right_area">
        <div class="top">
          <div class="title">{{ datalist.name }}</div>
          <div class="top_button">
            <el-button type="primary" size="small" @click="getShareInfo()">
              分享
            </el-button>
            <el-button
              type="info"
              size="small"
              v-if="datalist.status == 1"
              @click.native="doControlCourse(2)"
            >
              下架
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click.native="doControlCourse(1)"
              v-else
            >
              上架
            </el-button>
            <el-button type="danger" size="small" @click.native="doDelete()">
              删除
            </el-button>
          </div>
        </div>
        <div class="real_money" v-if="datalist.price_type == 1">
          ￥{{ datalist.price }}
          <strike v-if="datalist.costprice > 0">
            ￥{{ datalist.costprice }}
          </strike>
        </div>
        <div class="real_money" v-if="datalist.price_type == 2">
          免费
          <strike v-if="datalist.costprice > 0">
            ￥{{ datalist.costprice }}
          </strike>
        </div>
        <div class="real_money" v-if="datalist.price_type == 3">
          密码:{{ datalist.fee_pwd }}
        </div>
        <div class="detail">
          章节 {{ datalist.schedule_num }}
          <span>|</span>
          订单 {{ datalist.totalsales }}
          <span>|</span>
          累计收入 {{ datalist.courseprice }}
          <span>|</span>
          浏览次数 {{ datalist.views }}
          <!-- <span>|</span>
          报名人数 {{ datalist.totalsales }} -->
          <span>|</span>
          上课订阅 {{ datalist.subscribe_num }}
        </div>
        <div class="create_time">
          创建时间：{{
            datalist.createtime | formatTimeStamp('yyyy-MM-dd hh:mm')
          }}
        </div>
      </div>
    </div>
    <div class="edit-wrap" :style="`min-height:${availHeight}px`">
      <div class="tab-wrap">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="1">
            <BasicInfo
              @updateheader="updateheader"
              v-if="activeName == 1"
              :isEdit="true"
              courseType="4"
            ></BasicInfo>
          </el-tab-pane>
          <el-tab-pane label="内容管理" name="2">
            <addContent
              @updateheader3="updateheader3"
              v-if="activeName == 2"
              :isEdit="true"
              courseType="4"
            ></addContent>
          </el-tab-pane>
          <el-tab-pane label="课程设置" name="3">
            <CourseSetting
              v-if="activeName == 3"
              :isEdit="true"
              courseType="4"
            ></CourseSetting>
          </el-tab-pane>
          <el-tab-pane label="关联售卖" name="4">
            <associatedSelling
              v-if="activeName == 4"
              :isEdit="true"
              :courseType="courseType"
              :activeName="activeName"
            ></associatedSelling>
          </el-tab-pane>
          <el-tab-pane label="关联资料" name="9">
            <associatedSelling
              v-if="activeName == 9"
              :isEdit="true"
              :courseType="courseType"
              :activeName="activeName"
            ></associatedSelling>
          </el-tab-pane>
          <el-tab-pane label="评论管理" name="5">
            <CommentManage v-if="activeName == 5"></CommentManage>
          </el-tab-pane>
          <el-tab-pane label="订单管理" name="6">
            <OrderManage
              @leave="leaveConfirm = 1"
              v-if="activeName == 6"
            ></OrderManage>
          </el-tab-pane>
          <el-tab-pane label="订阅管理" name="7">
            <subscription
              @subscriptionUpdate="subscriptionUpdate"
              v-if="activeName == 7"
            ></subscription>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 删除课程 -->
    <CourseConfirm
      v-if="delId"
      title="删除课程"
      :iskecheng="kecheng"
      :isSmallPop="true"
      @cancelFn="toggleShowDel"
      @cancelFn2="toggleShowDel2"
      @save="getDel(delId, pos, checked)"
    >
      <h4 class="reset-txt">删除后，已购买的用户无法查看，是否删除该课程？</h4>
      <div
        class="checkbox-wrap"
        v-show="currentCourseType == 1"
        :class="{ 'is-choose': !checked }"
      >
        <el-checkbox label="同时删除回放" v-model="checked"></el-checkbox>
        <p v-if="checked">删除后无法恢复，请慎重操作</p>
      </div>
    </CourseConfirm>

    <!--分享弹窗-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfo"
    ></sharePop>

    <CourseConfirm
      v-if="downId"
      title="下架课程"
      :isSmallPop="true"
      @cancelFn="toggleShowDownOrUp"
      @save="getDownOrUp(downId, pos, 2)"
    >
      <h4 class="reset-txt">
        下架后已购买的用户仍可以查看，未购买的用户将无法购买，确认下架吗？
      </h4>
    </CourseConfirm>
  </div>
</template>
<script>
import API from '@/request/api'
import sharePop from '@/components/coursePop/sharePop'
import CourseConfirm from '@/components/CourseConfirm'
import BasicInfo from '@/components/course/BasicInfo'
import subscription from '@/components/course/subscription'
import CourseSetting from '@/components/course/CourseSetting'
import associatedSelling from '@/components/course/AssociatedSelling/AssociatedSelling'
import addContent from '@/components/course/AddContent'
import CommentManage from '@/components/course/CommentManage'
import OrderManage from '@/components/course/OrderManage'
import beforeLeave from '@/mixin/LeavePagePrompt'

export default {
  mixins: [beforeLeave],

  data() {
    return {
      kecheng: 1,
      activeName: '2',
      availHeight: '',

      datalist: [],
      shareInfo: {},
      isShowLeave: false,
      checked: false,
      currentCourseType: '',
      delId: '',
      sharePop: false,
      shareId: '',
      downId: '',
    }
  },
  components: {
    sharePop,
    CourseConfirm,
    subscription,
    BasicInfo,
    CourseSetting,
    associatedSelling,
    addContent,
    CommentManage,
    OrderManage,
  },

  created() {
    this.getCourseHeader()
    this.activeName = `${this.$route.query.index || ''}` || '2'
  },

  mounted() {
    this.availHeight = window.screen.availHeight - 60
  },
  methods: {
    subscriptionUpdate() {
      this.getCourseHeader()
    },
    updateheader() {
      this.getCourseHeader()
    },
    updateheader3() {
      this.getCourseHeader()
    },
    getCourseHeader() {
      this.$http({
        url: '/course/courseHeader',
        data: {
          course_id: this.$route.query.course_id,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.datalist = data
          }
        },
      })
    },

    toggleShowDownOrUp() {
      this.downId = ''
    },
    // 上下架课程
    getDownOrUp(course_id, pos, status) {
      const self = this
      self.$http({
        name: API.isShelves_Api.name,
        url: API.isShelves_Api.url,
        data: {
          course_id,
          status,
        },
        callback(res) {
          if (res.code == 200) {
            self.getCourseHeader()
            self.$root.prompt({
              msg: status == 2 ? '下架成功' : '上架成功',
              type: 'success',
            })
            self.toggleShowDownOrUp()
          }
        },
      })
    },
    // 上下架课程
    doControlCourse(val) {
      this.pos = this.$route.query.index
      const course_id = this.$route.query.course_id
      if (val == 2) {
        this.downId = course_id
      } else {
        this.getDownOrUp(course_id, this.pos, val)
      }
    },
    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
    // 获取分享课程数据
    getShareInfo() {
      const self = this
      const course_id = this.$route.query.course_id
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          course_id,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = course_id
            self.shareInfo = data
          }
        },
      })
    },
    // 删除课程弹窗
    toggleShowDel() {
      this.delId = ''
      this.currentCourseType = ''
      this.checked = false
      this.$router.push({ path: '/courseSeries' })
    },
    toggleShowDel2() {
      this.delId = ''
      this.currentCourseType = ''
      this.checked = false
    },
    // 删除课程
    getDel(course_id, pos, checked) {
      const self = this
      self.$http({
        name: API.delCourse_Api.name,
        url: API.delCourse_Api.url,
        data: {
          course_id,
          del_record: checked ? 1 : 2,
        },
        callback(res) {
          if (res.code == 200) {
            self.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            self.leaveConfirm = 1
            self.toggleShowDel()
          }
        },
      })
    },
    doDelete() {
      this.pos = this.$route.query.index
      this.delId = this.$route.query.course_id
      this.currentCourseType = this.$route.query.course_type
    },
  },
}
</script>

<style lang="less" scoped>
.top_button {
  /deep/ .el-button {
    width: 72px;
    height: 30px;
    padding: 0;
    margin-right: 10px;
  }
}
/deep/ .el-tabs__nav-scroll {
  padding: 0 20px;
}
</style>
<style lang="scss" scoped>
.reset-txt {
  padding: 10px 20px 0 20px;
  margin-bottom: 70px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
.edit_details {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  .tu {
    width: 200px;
    height: 113px;
    background-color: grey;
    position: relative;
    .tu_font {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px;
      background-color: #000000;
      color: #ffffff;
      line-height: 12px;
      font-size: 12px;
    }
  }
  .right_area {
    width: 100%;
    margin-left: 20px;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        width: calc(100% - 296px);
        margin-right: 30px;
        font-size: 16px;
        color: #363636;
        line-height: 20px;
      }
      .top_button {
        width: 266px;
      }
    }
    .real_money {
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      color: #ff3535;
      line-height: 14px;
      strike {
        margin-left: 5px;
        font-size: 12px;
        color: #999999;
        line-height: 12px;
      }
    }
    .detail {
      margin-top: 14px;
      font-size: 12px;
      color: #666666;
      line-height: 12px;
      span {
        margin: 8px;
      }
    }
    .create_time {
      margin-top: 10px;
      font-size: 12px;
      color: #666666;
      line-height: 12px;
    }
  }
}
.edit-wrap {
  background: #fff;
  ::v-deep .el-tabs__item.is-active,
  ::v-deep .el-tabs__item:hover {
    color: #0aa29b;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #0aa29b;
  }
  ::v-deep .el-tabs__item {
    height: 44px;
    line-height: 44px;
  }
  .tab-wrap {
    padding-left: 20px;
  }
}
</style>
